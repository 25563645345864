import { Message } from 'globals/interfaces';
import { USER_ROLES } from 'common/components/constant';
import { getDeliveredStatusWithDateTime } from 'helpers/messages';
import { useAppSelector } from 'app/hooks';
import { useEffect, useRef } from 'react';
import { findLastIndex, get } from 'lodash';

import Alvy from 'assets/alvy.png';

type Props = {
  chatMessages: Message[];
  searchQuery?: string;
  searchedMessages?: Message[];
  selectedMessageIndex?: number | null;
};

const ChatMessages = ({
  chatMessages,
  searchQuery,
  selectedMessageIndex,
  searchedMessages,
}: Props) => {
  const groupedMessages = [];
  let currentUserRole = null;

  const conversationRef = useRef<HTMLDivElement>();
  const currentTimeStamp = useAppSelector((state) => state.playbackVideo.currentPlayingTime);

  useEffect(() => {
    if (currentTimeStamp) {
      const activeAlertIndex = findLastIndex(chatMessages, (message) => {
        return message.created_at < currentTimeStamp;
      });
      // @ts-ignore
      const chatElement: HTMLElement = conversationRef?.current?.childNodes[activeAlertIndex];
      chatElement?.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
        block: 'start',
      });
    }
  }, [currentTimeStamp]);

  useEffect(() => {
    if (searchedMessages?.length && selectedMessageIndex !== null) {
      const selectedMessage = get(searchedMessages[selectedMessageIndex], 'occurrences', 0);
      if (selectedMessage > 0) {
        const messageToScrollTo = searchedMessages[selectedMessageIndex];
        const selectedMessageElement = conversationRef.current?.querySelector(
          `[data-message-id="${get(messageToScrollTo, 'id')}"]`,
        );
        if (selectedMessageElement instanceof HTMLElement) {
          selectedMessageElement.scrollIntoView({
            behavior: 'smooth',
            inline: 'start',
            block: 'start',
          });
        }
      }
    }
  }, [searchedMessages, selectedMessageIndex]);

  const escapeRegExp = (str) => {
    return str?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const renderHtmlContent = (message: string) => {
    const containsHtmlTags = /<[^>]+>/g.test(message);
    if (containsHtmlTags) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(message, 'text/html');
      const replaceTextNodes = (node: Node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const regex = new RegExp(`(${escapeRegExp(searchQuery)})`, 'gi');
          const replacedText = node.textContent?.replace(
            regex,
            `<span class="text-blue-500 bg-white">$1</span>`,
          );
          if (replacedText) {
            const span = document.createElement('span');
            span.innerHTML = replacedText;
            node.parentNode?.replaceChild(span, node);
          }
        } else {
          node.childNodes.forEach(replaceTextNodes);
        }
      };

      doc.body.childNodes.forEach(replaceTextNodes);

      return (
        <div className="chat-style" dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }}></div>
      );
    } else {
      const regex = new RegExp(`(${escapeRegExp(searchQuery)})`, 'gi');
      const replacedText = message.replace(regex, `<span class="text-blue-500 bg-white">$1</span>`);
      return <div className="chat-style" dangerouslySetInnerHTML={{ __html: replacedText }}></div>;
    }
  };

  chatMessages.forEach((message: Message) => {
    if (currentUserRole !== message.role) {
      groupedMessages.push({
        role: message.role,
        messages: [message],
        created_at: message.created_at,
      });
      currentUserRole = message.role;
    } else {
      groupedMessages[groupedMessages.length - 1].messages.push(message);
    }
  });

  return (
    <div className="flex flex-col m-2" ref={conversationRef}>
      {groupedMessages.map((group, index) => (
        <div
          key={index}
          className={`flex ${group.role === USER_ROLES.CANDIDATE || !group.role ? 'justify-start' : 'justify-end'
            }`}
        >
          <div className="flex w-full gap-2 mb-2">
            <div
              className={`${group.role === USER_ROLES.CANDIDATE || !group.role ? 'order-first' : 'order-last'
                }`}
            >
              <span
                className={`flex justify-center items-center h-6 w-6  text-xs rounded-full border shadow border-solid ${group.role === USER_ROLES.CANDIDATE || !group.role
                    ? 'bg-white'
                    : 'bg-orange-500 text-white'
                  }`}
              >
                {group.role === USER_ROLES.CANDIDATE || !group.role ? 'C' : group.role === USER_ROLES.ALVY ? <img src={Alvy} alt="alvy icon" /> : 'P'}
              </span>
            </div>
            <div className="flex flex-col w-full overflow-hidden">
              {group.messages.map((message: Message, i: number) => (
                <div
                  key={`${i}_msg`}
                  data-message-id={message.id}
                  className={`${group.role === USER_ROLES.CANDIDATE || !group.role
                      ? 'justify-start'
                      : 'justify-end'
                    } flex flex-col w-full  mb-2`}
                >
                  <div
                    className={`${group.role === USER_ROLES.CANDIDATE || !group.role
                        ? 'justify-start'
                        : 'justify-end'
                      } inline-flex flex-wrap mb-2`}
                  >
                    {message.type === 'recording' ? (
                      <audio
                        controls
                        controlsList="nodownload"
                        src={message.url}
                        onError={(event) => {
                          const audioElement = event.target as HTMLMediaElement;
                          audioElement.load();
                        }}
                      ></audio>
                    ) : (
                      <>
                        <span
                          data-testid="message-text"
                          className={`${group.role === USER_ROLES.CANDIDATE || !group.role
                              ? 'bg-white'
                              : group.role === USER_ROLES.ALVY
                                ? 'bg-blue-700 text-white'
                                : 'bg-blue-500 text-white'
                            } border shadow border-solid p-3 border-gray-300 rounded max-w-full whitespace-normal overflow-auto`}
                        >
                          {message?.text && renderHtmlContent(message.text)}
                        </span>
                      </>
                    )}
                  </div>
                  <div
                    className={`flex text-8 text-gray-500  ${group.role === USER_ROLES.CANDIDATE || !group.role
                        ? 'justify-start'
                        : 'justify-end'
                      } leading-3`}
                  >
                    <span> {getDeliveredStatusWithDateTime(message)} </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatMessages;
