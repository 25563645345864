interface Props {
  firstName: string;
  lastName: string;
  email: string;
  joinTime: string;
  examWindow: string;
  completionTime: string;
}

const CandidateInfo = ({
  firstName,
  lastName,
  email,
  examWindow,
  joinTime,
  completionTime,
}: Props) => {
  const headerStyles = `text-sm text-slate-800 font-medium`;
  return (
    <div className="grid grid-cols-2 gap-4 text-xs text-slate-700 p-2">
      <div>
        <p className={headerStyles}>First Name</p>
        <p>{firstName || '--'}</p>
      </div>
      <div>
        <p className={headerStyles}>Last Name</p>
        <p>{lastName || '--'}</p>
      </div>
      <div>
        <p className={headerStyles}>Email</p>
        <p className="break-all">{email || '--'}</p>
      </div>
      <div>
        <p className={headerStyles}>Exam Window</p>
        <p>{examWindow || '--'}</p>
      </div>
      <div>
        <p className={headerStyles}>Join Time</p>
        <p>{joinTime || '--'}</p>
      </div>
      <div>
        <p className={headerStyles}>End Time</p>
        <p>{completionTime || '- Yet to -'}</p>
      </div>
    </div>
  );
};

export default CandidateInfo;
