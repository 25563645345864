export enum VerificationTypes {
  TestEnv = 'test_env',
  PhotoId = 'photo_id',
  FaceCapture = 'face_capture',
}

export enum ApprovalStatus {
  Pending = 'pending',
  Submitted = 'submitted',
  Approved = 'approved',
  Rejected = 'rejected',
  Stale = 'stale',
}

export enum TwilioCallStatus {
  Requested = 'requested',
  Initiated = 'initiated',
  Connected = 'connected',
  Disconnected = 'disconnected',
}

export enum TwilioCallEvents {
  Error = 'error',
  TokenWillExpire = 'tokenWillExpire',
  Ringing = 'ringing',
  Accept = 'accept',
  Disconnect = 'disconnect',
}

export enum SessionStatus {
  NoShow = 'no_show',
  Terminated = 'terminated',
  Completed = 'completed',
  Online = 'online',
  ReadyForReview = 'ready_for_review',
  Reviewed = 'reviewed',
}

export enum SessionScope {
  AiProctor = 'ai_proctor',
  CandidateAuth = 'candidate_auth',
  LiveProctor = 'live_proctor',
  RecordAndReview = 'record_and_review',
}

export enum ProviewRating {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export enum RecordingTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  ScreenRecording = 'screenshare',
}

export enum AlertSeverity {
  Critical = 'critical',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  None = 'none',
}

export enum StreamingProvider {
  Twilio = 'twilio',
  LiveKit = 'livekit',
}

export enum AlertTypes {
  SessionJoined = 3,
  SessionCompleted = 113,
  SessionTerminated = 144,
  PrimaryCameraRecordingStarted = 198,
  PrimaryCameraRecordingStopped = 199,
  SecondaryDeviceJoined = 211,
  SecondaryCameraRecordingStarted = 200,
  SecondaryCameraRecordingStopped = 201,
  ScreenRecordingStarted = 203,
  ScreenRecordingStopped = 204,
  ProctorRatingOverride = 136,
  SessionPlaybackUrl = 142,
  CronJobSessionCompleted = 151,
  TwilioCompositionEnqueued = 183,
  TwilioCompositionCompleted = 184,
  TwilioScreenCompositionEnqueued = 185,
  TwilioScreenCompositionCompleted = 186,
  TwilioSecondaryCameraCompositionEnqueued = 187,
  TwilioSecondaryCameraCompositionCompleted = 188,
  SecondaryCameraConnected = 189,
  SecondaryCameraDisconnected = 190,
  CandidateOnGoodBandwidth = 191,
  CandidateOnline = 193,
  UserClosedOrRefreshedProctoringSession = 194,
  TwilioCompositionFailed = 195,
  PrimaryCameraConnected = 196,
  PrimaryCameraDisconnected = 197,
  RoomCreated = 205,
  RoomEnded = 206,
  MultipleMonitorsDetected = 207,
  PrecheckApproved = 138,
  PrecheckRejected = 139
}

export enum FilterBy {
  Critical = 'critical',
  Public = 'public',
  Debug = 'debug'
}
