import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import {
  AlertSeverity,
  AlertTypes,
  FilterBy,
  RecordingTypes,
  VerificationTypes,
} from 'globals/enums';
import { Alert, Precheck, Recordings, SessionFile } from 'globals/interfaces';
import { findLast, findLastIndex } from 'lodash';
import { formatDate } from './date';
import { debugAlertTypeIds, defaultAlertTypes } from 'common/components/constant';
dayjs.extend(duration);
dayjs.extend(isBetween);

export type EventType = Alert | SessionFile;

export function subscribeToFullscreen(callback) {
  document.addEventListener('fullscreenchange', callback);
  return () => {
    document.removeEventListener('fullscreenchange', callback);
  };
}

export const getIsFullscreen = () => {
  return document.fullscreenElement !== null;
};

export const getGroupedAlertsBySessionJoined = (alerts: Alert[]) => {
  let temp = [];
  const groupedAlerts = alerts?.reduce((acc, alert: Alert) => {
    if (alert.alert_type_id === 3) {
      temp = [alert];
      acc.push(temp);
    } else {
      temp.push(alert);
    }
    return acc;
  }, []);

  return groupedAlerts;
};

export const getTotalSessionDuration = (groupedAlerts) => {
  const totalDuration = groupedAlerts?.reduce((acc, alerts) => {
    const startTimestamp: string = alerts[0]?.timestamp || alerts[0]?.updated_at;
    const endTimestamp: string =
      alerts[alerts.length - 1]?.timestamp || alerts[alerts.length - 1]?.updated_at;
    acc += dayjs(endTimestamp).diff(startTimestamp, 'second');
    return acc;
  }, 0);

  return totalDuration;
};

export const getIsTimestampWithinAlerts = (groupedAlerts, timestamp) => {
  const selectedAlertGroup = groupedAlerts.findIndex((alerts) => {
    const startTimestamp = dayjs(alerts[0].timestamp || alerts[0].updated_at);
    const endTimestamp = dayjs(
      alerts[alerts.length - 1].timestamp || alerts[alerts.length - 1].updated_at,
    );
    return dayjs(timestamp).isBetween(startTimestamp, endTimestamp, 'second', '[]');
  });

  return selectedAlertGroup !== -1;
};

export const getNextGroupedAlertsByTimestamp = (groupedAlerts, timestamp) => {
  const nextAlertGroup = groupedAlerts.find((alerts) => {
    const nextAlertStartTimestamp = dayjs(alerts[0].timestamp || alerts[0].updated_at);
    return (
      dayjs(nextAlertStartTimestamp).isAfter(timestamp) ||
      dayjs(nextAlertStartTimestamp).isSame(timestamp)
    );
  });

  return nextAlertGroup;
};

export const getNextAlertByTimestamp = (alerts: Alert[], currentTimestamp: string) => {
  const nextAlert = alerts?.find((alert) => {
    return dayjs(dayjs(alert.timestamp || alert.updated_at)).isAfter(currentTimestamp, 'second');
  });

  return nextAlert?.timestamp || nextAlert?.updated_at;
};

export const getPreviousAlertByTimestamp = (alerts: Alert[], currentTimestamp: string) => {
  const previousAlert = alerts?.findLast((alert) => {
    return dayjs(dayjs(alert.timestamp || alert.updated_at)).isBefore(currentTimestamp, 'second');
  });

  return previousAlert?.timestamp || previousAlert?.updated_at;
};

export const slideAlertIntoView = (
  currentTimestamp: string,
  alerts: Alert[],
  ref,
  delay?: boolean,
) => {
  if (currentTimestamp) {
    const activeAlertIndex = findLastIndex(alerts, (alert: any) => {
      return (
        dayjs(alert.timestamp || alert.updated_at).isBefore(currentTimestamp) ||
        dayjs(alert.timestamp || alert.updated_at).isSame(currentTimestamp, 'second')
      );
    });
    const alertElement: HTMLElement = ref?.current?.childNodes[activeAlertIndex] as HTMLElement;
    // To fix overlap of scrollIntoView event
    if (delay) {
      setTimeout(() => {
        alertElement?.scrollIntoView({
          behavior: 'smooth',
          inline: 'start',
          block: 'start',
        });
      }, 750);
      return;
    }
    alertElement?.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
      block: 'start',
    });
    return alertElement;
  }
};

export const getDistinctImpactAlerts = (alerts: Alert[]) => {
  const impactAlerts = alerts?.filter(
    (alert) => alert.severity && alert.severity !== AlertSeverity.None,
  );
  const distinctAlerts = impactAlerts?.reduce((acc, alert: any) => {
    const isAdded = acc.find((item) => item.alert_type_id === alert.alert_type_id);
    if (!isAdded) {
      const allAlertsOfType = impactAlerts.filter(
        (impactAlert: any) => alert.alert_type_id === impactAlert.alert_type_id,
      );
      const removedAlerts = allAlertsOfType.filter((alert: any) => alert.deleted);
      const addedCount = allAlertsOfType?.length - removedAlerts?.length || 0;
      const removedCount = removedAlerts?.length || 0;
      acc.push({ ...alert, addedCount, removedCount, totalCount: addedCount + removedCount });
    }
    return acc;
  }, []);

  return distinctAlerts;
};

export const getSessionCompletedTimestamp = (alerts) => {
  if (alerts?.length) {
    const sessionCompletedAlert = findLast(
      alerts,
      (alert) => alert.alert_type_id === AlertTypes.SessionCompleted || alert.alert_type_id === AlertTypes.SessionTerminated,
    );
    return sessionCompletedAlert && formatDate(sessionCompletedAlert?.timestamp);
  }
};

export const getVideoPlaybackAlerts = (alerts: Alert[], filterBy?: string[]) => {
  if (filterBy.includes(FilterBy.Critical)) {
    return alerts.filter(
      (a) =>
        [
          AlertSeverity.Critical,
          AlertSeverity.High,
          AlertSeverity.Medium,
          AlertSeverity.Low,
        ].includes(a.severity) || defaultAlertTypes.includes(a.alert_type_id),
    );
  } else {
    return alerts
      .filter((alert: Alert) => !debugAlertTypeIds.includes(alert.alert_type_id))
      .sort(
        (a: Alert, b: Alert) =>
          new Date(a.timestamp || a.updated_at).getTime() -
          new Date(b.timestamp || b.updated_at).getTime(),
      );
  }
};

export const getSortedListByTimestamp = (list: EventType[]) => {
  return list.sort(
    (a: EventType, b: EventType) =>
      new Date(a.timestamp || a.updated_at).getTime() -
      new Date(b.timestamp || b.updated_at).getTime(),
  );
};

export const getSortedSessionFilesByTimestamp = (list: SessionFile[]) => {
  if (!list) return;
  const sortedList: SessionFile[] = getSortedListByTimestamp(list) as SessionFile[];
  return sortedList;
};

export const getSortedAlertsByTimestamp = (list: Alert[]) => {
  if (!list) return;
  const sortedList: Alert[] = getSortedListByTimestamp(list) as Alert[];
  return sortedList;
};

export const timestampToSeconds = (timestamp: string) => {
  return new Date(timestamp).getTime() / 1000;
};

type StreamType =
  | RecordingTypes.Primary
  | RecordingTypes.Secondary
  | RecordingTypes.ScreenRecording;

export const getStreamTypeIds = (type: StreamType) => {
  let startAlertTypeId: number;
  let stopAlertTypeId: number;

  switch (type) {
    case RecordingTypes.Primary:
      startAlertTypeId = 198;
      stopAlertTypeId = 199;
      break;
    case RecordingTypes.Secondary:
      startAlertTypeId = 200;
      stopAlertTypeId = 201;
      break;
    default:
      startAlertTypeId = 203;
      stopAlertTypeId = 204;
  }

  return {
    start_alert_type_id: startAlertTypeId,
    stop_alert_type_id: stopAlertTypeId,
  };
};

export const getRecordingDurations = (alerts: Alert[], type: StreamType) => {
  const durations = [];

  const streamIds = getStreamTypeIds(type);
  const filteredAlerts = alerts?.filter(
    (i) =>
      i.alert_type_id === streamIds.start_alert_type_id ||
      i.alert_type_id === streamIds.stop_alert_type_id,
  );

  const recordingStartedAlerts = filteredAlerts.filter(
    (i) => i.alert_type_id === streamIds.start_alert_type_id,
  );
  const recordingStoppedAlerts = filteredAlerts.filter(
    (i) => i.alert_type_id === streamIds.stop_alert_type_id,
  );

  for (let i = 0; i <= recordingStartedAlerts.length - 1; i++) {
    const currentEvent = recordingStartedAlerts[i];
    const nextEvent = recordingStoppedAlerts[i];
    if (
      currentEvent?.alert_type_id === streamIds.start_alert_type_id &&
      nextEvent?.alert_type_id === streamIds.stop_alert_type_id
    ) {
      const startTime = timestampToSeconds(currentEvent.timestamp);
      const endTime = timestampToSeconds(nextEvent.timestamp);
      const duration = endTime - startTime;

      durations.push({
        startTimestamp: currentEvent.timestamp,
        endTimestamp: nextEvent.timestamp,
        duration: duration.toFixed(2),
      });
    }
  }

  return durations;
};

export const isTimestampInRange = (ranges, timestamp) => {
  const inputDate = new Date(timestamp);

  for (const range of ranges) {
    const startTimestamp = new Date(range.startTimestamp);
    const endTimestamp = new Date(range.endTimestamp);

    if (inputDate >= startTimestamp && inputDate <= endTimestamp) {
      return true;
    }
  }

  return false;
};

export const getVideoFeedsByCurrentPlayingTime = (
  videoFeeds,
  alerts,
  currentPlayingTime,
  streaming_provider,
) => {
  const videoFeedsByCurrentPlayingTime = Object.keys(videoFeeds).reduce((acc, feedName) => {
    const filteredFeeds = videoFeeds[feedName].find((feed) => {
      if (feed.start_time && feed.end_time) {
        return (
          dayjs(currentPlayingTime).isBefore(feed.end_time, 'second') ||
          dayjs(currentPlayingTime).isSame(feed.end_time, 'second')
        );
      }
    });
    if (filteredFeeds?.path) {
      return { ...acc, [feedName]: filteredFeeds?.path };
    } else {
      return acc;
    }
  }, {});

  return videoFeedsByCurrentPlayingTime;
};

export const getSeekTimeDuration = (
  currentPlayingTime: string,
  recordings: Recordings,
  type: RecordingTypes,
) => {
  const recordingStartTimestamp = recordings[type]?.findLast((feed) => {
    if (feed.start_time && feed.end_time) {
      return dayjs(currentPlayingTime).isBetween(feed.start_time, feed.end_time, 'second', '[]');
    }
  })?.start_time;

  const totalDuration = recordingStartTimestamp
    ? dayjs(currentPlayingTime).diff(recordingStartTimestamp, 'second')
    : 0;

  return totalDuration.toFixed(2);
};

export const getPostSessionPrechecks = (
  prechecks,
): Record<'idChecks' | 'envChecks', Precheck[]> => {
  const isPhotoIdEnabled = prechecks.some(
    (precheck: Precheck) => precheck.step === VerificationTypes.PhotoId,
  );
  const isFaceCaptureEnabled = prechecks.some(
    (precheck: Precheck) => precheck.step === VerificationTypes.FaceCapture,
  );

  const isVerificationEnabled = isPhotoIdEnabled && isFaceCaptureEnabled;

  const idChecks = prechecks
    ?.filter((precheck: Precheck) => precheck.step !== VerificationTypes.TestEnv)
    .reduce((acc, precheck: Precheck) => {
      if (isVerificationEnabled) {
        if (precheck.step === VerificationTypes.PhotoId) {
          const matchingFaceCapture = prechecks.find(
            (face_capture: Precheck) =>
              face_capture.step === VerificationTypes.FaceCapture &&
              face_capture.reference_step_uuid === precheck.id,
          );
          matchingFaceCapture &&
            acc.push({
              ...precheck,
              faceCaptureUrl: matchingFaceCapture.resource_url || matchingFaceCapture.path || '',
            });
        }
      } else {
        acc.push(precheck);
      }
      return acc;
    }, []);

  const envChecks = prechecks.filter(
    (precheck: Precheck) => precheck.step === VerificationTypes.TestEnv,
  );

  return {
    idChecks,
    envChecks,
  };
};
