import { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';

export interface CustomVideoElement extends HTMLVideoElement {
  seekTo: (currentTime: number) => void;
}

interface Props {
  elementRef: React.RefObject<CustomVideoElement>;
  width: number | string;
  height: number | string;
  volume: number;
  muted: boolean;
  playing: boolean;
  className?: string;
  url: string;
  kind: 'mp4' | 'hls';
  playbackRate: number;
  onPause?: () => void;
  onProgress?: () => void;
  onClick?: () => void;
  onBuffer?: () => void;
  onBufferEnd?: () => void;
  onReady?: () => void;
  onPlay?: () => void;
  onEnded?: () => void;
  onLoadFailure?: (isLoaded: boolean) => void;
}

const HLSPlayer = ({
  elementRef,
  width,
  height,
  volume,
  muted,
  kind,
  playing,
  className,
  url,
  playbackRate,
  onPause,
  onProgress,
  onClick,
  onBuffer,
  onBufferEnd,
  onReady,
  onPlay,
  onEnded,
  onLoadFailure,
}: Props) => {
  const videoTimeRef = useRef(0);
  const hlsInstance = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleTimeUpdate = (e) => {
    const currentTime = e.target.currentTime;
    const diff = currentTime - videoTimeRef.current;
    if (videoTimeRef.current != currentTime && (diff >= playbackRate || diff < 0)) {
      videoTimeRef.current = currentTime;
      if (onProgress) {
        onProgress();
      }
    }
  };

  useEffect(() => {
    const video = elementRef.current;
    if (url && Hls.isSupported() && kind === 'hls') {
      hlsInstance.current = new Hls();

      // Handle MANIFEST_PARSED event to modify segment URLs
      hlsInstance.current.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
        data.levels.forEach((level) => {
          level.details.fragments.forEach((fragment) => {
            // Append the token as a URL parameter to each .ts file
            const token = url && url.split('?')[1];
            if (token) {
              fragment.url = `${fragment.url}?${token}`;
            }
          });
        });
      });
      // Load the HLS manifest file
      hlsInstance.current.loadSource(url);
      hlsInstance.current.attachMedia(video);
      hlsInstance.current.on(Hls.Events.FRAG_LOADED, () => onLoadFailure(false));
      hlsInstance.current.on(Hls.Events.ERROR, () => onLoadFailure(true));
    }

    return () => {
      if (hlsInstance.current) {
        hlsInstance.current.destroy();
      }
    };
  }, [url]);

  useEffect(() => {
    if (!elementRef.current) return;
    if (playing) {
      setIsLoading(true);
      elementRef.current
        .play()
        ?.then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
      elementRef.current.addEventListener('timeupdate', (e) => handleTimeUpdate(e));
    } else {
      elementRef.current.pause();
    }
    return () => {
      if (elementRef.current) {
        elementRef.current.removeEventListener('timeupdate', (e) => handleTimeUpdate(e));
      }
    };
  }, [playing, playbackRate, url]);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.addEventListener('click', onClick);
      elementRef.current.addEventListener('waiting', onBuffer);
      elementRef.current.addEventListener('playing', onBufferEnd);
      elementRef.current.addEventListener('play', onPlay);
      elementRef.current.addEventListener('pause', onPause);
      elementRef.current.addEventListener('canplay', onReady);
      elementRef.current.addEventListener('ended', onEnded);
      elementRef.current.seekTo = (currentTime: number) => {
        if (elementRef.current) {
          elementRef.current.currentTime = currentTime;
        }
      };
    }

    return () => {
      if (elementRef.current) {
        elementRef.current.removeEventListener('click', onClick);
        elementRef.current.removeEventListener('waiting', onBuffer);
        elementRef.current.removeEventListener('playing', onBufferEnd);
        elementRef.current.removeEventListener('play', onPlay);
        elementRef.current.removeEventListener('pause', onPause);
        elementRef.current.removeEventListener('canplay', onReady);
        elementRef.current.removeEventListener('ended', onEnded);
      }
    };
  }, []);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.volume = volume;
      elementRef.current.muted = muted;
      elementRef.current.playbackRate = playbackRate;
    }

    return () => {
      if (elementRef.current) {
        elementRef.current.volume = 1;
        elementRef.current.muted = false;
        elementRef.current.playbackRate = 1;
      }
    };
  }, [volume, muted, playbackRate]);

  return (
    <div className="relative w-full h-full">
      <video
        data-testid="videoElement"
        src={kind === 'mp4' ? url : undefined}
        className={className}
        controls={false}
        ref={elementRef}
        width={width}
        height={height}
      />
      {isLoading && (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
          <span className="flex items-center justify-center" role="spinbutton">
            <span className="w-6 h-6 border-2 border-gray-400 rounded-full animate-spin border-r-transparent"></span>
          </span>
        </div>
      )}
    </div>
  );
};

export default HLSPlayer;
