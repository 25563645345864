import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getSortedAlertsByTimestamp, getSortedSessionFilesByTimestamp } from 'helpers/playback';
import { Alert } from 'globals/interfaces';
import { RecordingTypes } from 'globals/enums';

interface UpdateProviewIndexPayload {
  attendeeId: number;
  newRating: string;
  overrideReason: string;
  predefinedReason?: string;
  sessionId: string;
}

const postSessionEmbedApi = createApi({
  reducerPath: 'post-session-embed',
  tagTypes: ['PostSession'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.PROVIEW_API_URL,
    prepareHeaders: (headers: Headers) => {
      headers.set('app-id', process.env.REACT_APP_ID as string);
      const urlParams = new URLSearchParams(window.location.search);
      const isEmbeddedView = window.location.pathname.toLowerCase().includes('embedded');
      const storedToken = !isEmbeddedView && JSON.parse(localStorage.getItem('live-proctor-session'))?.access_token;
      const playbackToken = urlParams.get('token');
      if (isEmbeddedView && playbackToken) {
        headers.set('Token', `Bearer ${playbackToken}`);
      } else if(storedToken) {
        headers.set('Authorization', `Bearer ${storedToken}`);
      }
    },
  }),
  endpoints: (builder) => ({
    getPostSession: builder.query({
      query: (payload) => ({
        url: `v1/session/${payload.session_uuid}?is_proctor_ng=true`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        const sortedSessionFiles = getSortedSessionFilesByTimestamp(response.session_files);
        const sortedAlerts: Alert[] = getSortedAlertsByTimestamp(response.alerts)
          .filter((alert: Alert) => alert.name)
          .map((alert: Alert) => ({ ...alert, timestamp: alert.timestamp || alert.updated_at }));

        const availableSettings = [
          response?.primary_device && RecordingTypes.Primary,
          response?.secondary_device && RecordingTypes.Secondary,
          response?.screen_recording && RecordingTypes.ScreenRecording,
        ].filter(Boolean);

        const isRecordingAvailable =
          response?.recordings &&
          Object.keys(response.recordings).some((type) => response.recordings[type].length);

        return {
          ...response,
          availableSettings,
          isRecordingAvailable,
          alerts: sortedAlerts || [],
          session_files: sortedSessionFiles,
        };
      },
      transformErrorResponse: (error) => {
        if (error.status === 401) {
          window.location.href = '/auth-error';
        }
      },
      providesTags: ['PostSession'],
    }),
    getChatHistory: builder.query<any, any>({
      query: (payload) => ({
        url: `v1/conversation-history/${payload.session_uuid}`,
        method: 'GET',
      }),
      transformErrorResponse: (error) => {
        if (error.status === 401) {
          window.location.href = '/auth-error';
        }
      },
    }),
    getPrecheckHistory: builder.query<any, any>({
      query: (payload) => ({
        url: `v1/precheck-history/${payload.session_uuid}`,
        method: 'GET',
      }),
      transformErrorResponse: (error) => {
        if (error.status === 401) {
          window.location.href = '/auth-error';
        }
      },
    }),
    updateProviewIndex: builder.mutation<any, UpdateProviewIndexPayload>({
      query: (payload) => ({
        url: `v1/attendee/${payload.attendeeId}/rating/session`,
        method: 'PATCH',
        body: {
          new_rating: payload.newRating,
          override_reason: payload.overrideReason,
          predefined_rating_modified_reason: payload.predefinedReason,
          session_id: payload.sessionId,
        },
      }),
      invalidatesTags: ['PostSession'],
    }),
  }),
});

export const { useGetPostSessionQuery, useGetChatHistoryQuery, useGetPrecheckHistoryQuery, useUpdateProviewIndexMutation } =
  postSessionEmbedApi;

export default postSessionEmbedApi;
